import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: any) => ({
	toolbar: {
		...theme.mixins.toolbar,
		marginTop: 24
	},
	iconBg: {
		backgroundColor: "#ECE6F8",
		padding: 8,
		borderRadius: 8,
		width: 40,
		height: 40,
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	divider: {
		width: "100%",
		height: 1,
		borderColor: "#E7E7E7",
		borderStyle: "solid",
		backgroundColor: "#E7E7E7",
		marginTop: 16
	},
	textField: {
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				border: "1px solid #E7E7E7",
				borderRadius: 8,
				minHeight: 42,
				minWidth: "100%"
			},
			"&:hover fieldset": {
				border: "1px solid #E7E7E7"
			},
			"&.Mui-focused fieldset": {
				border: "1px solid #E7E7E7"
			}
		}
	},
	listItem: {
		paddingTop: 0,
		paddingBottom: 0,
		marginBottom: 0,
		margin: 0
	},
	listItemIcon: {
		minWidth: 12
	},
	button: {
		borderRadius: 40,
		textTransform: "initial",
		fontSize: 14,
		fontWeight: "bold",
		borderWidth: 2
	},
	backdrop: {
		zIndex: 10,
		color: "#fff"
	}
}));

export default useStyles;
