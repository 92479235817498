import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Drawer,
	IconButton,
	InputAdornment,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	TextField,
	Typography
} from "@material-ui/core";
import { toastError, toastSuccess } from "helpers/toast";
import ls from "Localization";
import React, { useCallback, useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FiCircle } from "react-icons/fi";
import { HiOutlineLockClosed } from "react-icons/hi2";
import { useSelector } from "react-redux";
import { State } from "store/reducers";
import { initialStateLogin } from "store/reducers/login/types/interfaces";

import * as api from "../../services/api";
import useStyles from "./styles";

interface Props {
	open: boolean;
	onClose: () => void;
}

interface PasswordErrors {
	atLeastOneNumber?: boolean;
	atLeastOneUppercase?: boolean;
	atLeastOneLowercase?: boolean;
	atLeastEightCharacters?: boolean;
	newPasswordIsDifferentFromOldPassword?: boolean;
	newPasswordDifferentFromConfirmMessage?: string;
}

const ChangePasswordDrawer: React.FC<Props> = ({ open, onClose }) => {
	const styles = useStyles({});

	const { token, user } = useSelector<State, initialStateLogin>(s => s.login);
	const [showPassword, setShowPassword] = useState<{
		oldPassword: boolean;
		showNewPassword: boolean;
		showConfirmPassword: boolean;
	}>({
		oldPassword: false,
		showNewPassword: false,
		showConfirmPassword: false
	});

	const [passwordErrors, setPasswordErrors] = useState<PasswordErrors>({
		atLeastOneNumber: undefined,
		atLeastOneUppercase: undefined,
		atLeastOneLowercase: undefined,
		atLeastEightCharacters: undefined,
		newPasswordIsDifferentFromOldPassword: undefined,
		newPasswordDifferentFromConfirmMessage: ""
	});

	const [passwords, setPasswords] = useState<{
		oldPassword: string;
		newPassword: string;
		confirmPassword: string;
	}>({
		oldPassword: "",
		newPassword: "",
		confirmPassword: ""
	});

	const [focusedField, setFocusedField] = useState<string | null>(null);
	const [loading, setLoading] = useState<boolean>(false);

	const validatePassword = useCallback(
		(password: string, type: string) => {
			const passwordErrorsLocal: PasswordErrors = {
				...passwordErrors
			};

			if (type === "newPassword") {
				passwordErrorsLocal.atLeastEightCharacters = password.length < 8;

				passwordErrorsLocal.atLeastOneUppercase = !/[A-Z]/.test(password);

				passwordErrorsLocal.atLeastOneLowercase = !/[a-z]/.test(password);

				passwordErrorsLocal.atLeastOneNumber = !/[0-9]/.test(password);

				passwordErrorsLocal.newPasswordIsDifferentFromOldPassword =
					passwords?.oldPassword &&
					type === "newPassword" &&
					password === passwords.oldPassword;
			}

			if (
				passwords.newPassword &&
				passwords.confirmPassword &&
				(type === "confirmPassword" || type === "newPassword") &&
				(password !== passwords.newPassword ||
					passwords.newPassword !== passwords.confirmPassword)
			) {
				passwordErrorsLocal.newPasswordDifferentFromConfirmMessage =
					"As senhas não coincidem. Certifique-se de que ambas são iguais";
			} else {
				passwordErrorsLocal.newPasswordDifferentFromConfirmMessage = "";
			}

			setPasswordErrors(prev => ({
				...prev,
				...passwordErrorsLocal
			}));
		},
		[
			passwordErrors,
			passwords.confirmPassword,
			passwords.newPassword,
			passwords.oldPassword
		]
	);

	useEffect(() => {
		validatePassword(passwords[focusedField], focusedField);
	}, [focusedField, passwords]);

	const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setPasswords(prev => ({
			...prev,
			[name]: value
		}));
		if (name !== "oldPassword") validatePassword(value, name);
	};
	const handleTogglePasswordVisibility = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		setShowPassword(prev => ({
			...prev,
			[event.currentTarget.id]: !prev[event.currentTarget.id]
		}));
	};

	const handleFocus = (field: string) => {
		setFocusedField(field);
	};

	const handleBlur = () => {
		setFocusedField(null);
	};

	const handleSubmit = async () => {
		try {
			setLoading(true);

			const response = await api.sendPut(
				`/password/user/${user?.id}`,
				{
					OldPassword: passwords.oldPassword,
					NewPassword: passwords.newPassword,
					ConfirmNewPassword: passwords.confirmPassword
				},
				{
					Authorization: `Bearer ${token}`
				}
			);

			if (response?.status >= 400) {
				if (response?.status === 404) {
					throw new Error("Usuário ou rota não encontrada");
				}
				throw new Error("Algo inesperado aconteceu");
			}

			toastSuccess("Senha alterada com sucesso");
			setPasswords({
				oldPassword: "",
				newPassword: "",
				confirmPassword: ""
			});
			onClose();
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		} catch (e) {
			const error: any = e;
			if (error && !!error?.message) toastError(error?.message || "");
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<Backdrop open={open} onClick={onClose} className={styles.backdrop} />
			<Drawer anchor="right" variant="persistent" onClose={onClose} open={open}>
				<div className={styles.toolbar} />
				<Box flex={1} display={"flex"} flexDirection={"column"} width={530}>
					<Box
						alignItems={"flex-start"}
						justifyContent={"flex-start"}
						display={"flex"}
						flexDirection={"column"}
						gridRowGap={16}
						paddingLeft={3}
						paddingRight={3}
					>
						<div className={styles.iconBg}>
							<HiOutlineLockClosed size={24} color="#5734DA" />
						</div>
						<Typography variant="h5" style={{ fontWeight: "bold" }}>
							{ls.changePassword}
						</Typography>
						<Typography
							style={{
								color: "#7D7D7D"
							}}
						>
							{ls.changePasswordInstructions}
						</Typography>
					</Box>
					<hr className={styles.divider} />
					<Box
						flex={1}
						display={"flex"}
						flexDirection={"column"}
						gridRowGap={32}
						padding={3}
						paddingTop={4}
						width={"100%"}
					>
						<Box
							display={"flex"}
							alignItems={"flex-start"}
							flexDirection={"column"}
							gridRowGap={10}
						>
							<Typography
								key={"label"}
								variant="body2"
								style={{
									fontWeight: "bold",
									color: "#7D7D7D"
								}}
							>
								{ls.oldPassword}
							</Typography>
							<TextField
								placeholder={ls.insertOldPassword}
								type={showPassword.oldPassword ? "text" : "password"}
								className={styles.textField}
								variant="outlined"
								style={{ width: "100%" }}
								name="oldPassword"
								onChange={handlePasswordChange}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												id="oldPassword"
												onClick={handleTogglePasswordVisibility}
											>
												{!showPassword.oldPassword ? <FaEyeSlash /> : <FaEye />}
											</IconButton>
										</InputAdornment>
									)
								}}
							/>
						</Box>
						<Box
							display={"flex"}
							alignItems={"flex-start"}
							flexDirection={"column"}
							gridRowGap={10}
						>
							<Typography
								key={"label"}
								variant="body2"
								style={{
									fontWeight: "bold",
									color: "#7D7D7D"
								}}
							>
								{ls.newPassword}
							</Typography>
							<TextField
								placeholder={ls.insertNewPassword}
								type={showPassword.showNewPassword ? "text" : "password"}
								className={styles.textField}
								variant="outlined"
								style={{ width: "100%" }}
								name="newPassword"
								onChange={handlePasswordChange}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												id="showNewPassword"
												onClick={handleTogglePasswordVisibility}
											>
												{!showPassword.showNewPassword ? (
													<FaEyeSlash />
												) : (
													<FaEye />
												)}
											</IconButton>
										</InputAdornment>
									)
								}}
								onFocus={() => handleFocus("newPassword")}
								onBlur={handleBlur}
								error={
									!!Object.values(passwordErrors).some(Boolean) &&
									focusedField === "newPassword"
								}
							/>
							<List
								style={{
									rowGap: 8,
									color: "#7D7D7D"
								}}
							>
								<ListItem className={styles.listItem}>
									<ListItemText primary="Para criar sua nova senha, siga as orientações abaixo" />
								</ListItem>

								<ListItem className={styles.listItem}>
									<ListItemIcon className={styles.listItemIcon}>
										<FiCircle
											fill={
												passwordErrors.atLeastEightCharacters
													? "#B30012"
													: "#7D7D7D"
											}
											size={4}
										/>
									</ListItemIcon>
									<ListItemText
										primary="Mínimo de 8 caracteres"
										style={{
											color: passwordErrors.atLeastEightCharacters
												? "#B30012"
												: "#7D7D7D"
										}}
									/>
								</ListItem>
								<ListItem className={styles.listItem}>
									<ListItemIcon className={styles.listItemIcon}>
										<FiCircle
											fill={
												passwordErrors.atLeastOneUppercase
													? "#B30012"
													: "#7D7D7D"
											}
											size={4}
										/>
									</ListItemIcon>
									<ListItemText
										primary="Uma letra maiúscula"
										style={{
											color: passwordErrors.atLeastOneUppercase
												? "#B30012"
												: "#7D7D7D"
										}}
									/>
								</ListItem>
								<ListItem className={styles.listItem}>
									<ListItemIcon className={styles.listItemIcon}>
										<FiCircle
											fill={
												passwordErrors.atLeastOneLowercase
													? "#B30012"
													: "#7D7D7D"
											}
											size={4}
										/>
									</ListItemIcon>
									<ListItemText
										primary="Uma letra minúscula"
										style={{
											color: passwordErrors.atLeastOneLowercase
												? "#B30012"
												: "#7D7D7D"
										}}
									/>
								</ListItem>
								<ListItem className={styles.listItem}>
									<ListItemIcon className={styles.listItemIcon}>
										<FiCircle
											fill={
												passwordErrors.atLeastOneNumber ? "#B30012" : "#7D7D7D"
											}
											size={4}
										/>
									</ListItemIcon>
									<ListItemText
										primary="Pelo menos um número"
										style={{
											color: passwordErrors.atLeastOneNumber
												? "#B30012"
												: "#7D7D7D"
										}}
									/>
								</ListItem>

								<ListItem className={styles.listItem}>
									<ListItemIcon className={styles.listItemIcon}>
										<FiCircle
											fill={
												passwordErrors.newPasswordIsDifferentFromOldPassword
													? "#B30012"
													: "#7D7D7D"
											}
											size={4}
										/>
									</ListItemIcon>
									<ListItemText
										primary="A nova senha deve ser diferente da anterior"
										style={{
											color:
												passwordErrors.newPasswordIsDifferentFromOldPassword
													? "#B30012"
													: "#7D7D7D"
										}}
									/>
								</ListItem>
							</List>
						</Box>
					</Box>
					<Box
						display={"flex"}
						alignItems={"flex-start"}
						flexDirection={"column"}
						gridRowGap={10}
						padding={3}
						paddingTop={0}
					>
						<Typography
							key={"label"}
							variant="body2"
							style={{
								fontWeight: "bold",
								color: "#7D7D7D"
							}}
						>
							{ls.confirmNewPassword}
						</Typography>
						<TextField
							placeholder={ls.insertConfirmNewPassword}
							type={showPassword.showConfirmPassword ? "text" : "password"}
							className={styles.textField}
							variant="outlined"
							style={{ width: "100%" }}
							name="confirmPassword"
							onChange={handlePasswordChange}
							onFocus={() => handleFocus("confirmPassword")}
							onBlur={handleBlur}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											id="showConfirmPassword"
											onClick={handleTogglePasswordVisibility}
										>
											{!showPassword.showConfirmPassword ? (
												<FaEyeSlash />
											) : (
												<FaEye />
											)}
										</IconButton>
									</InputAdornment>
								)
							}}
							error={!!passwordErrors?.newPasswordDifferentFromConfirmMessage}
							helperText={
								!!passwordErrors?.newPasswordDifferentFromConfirmMessage && (
									<Typography style={{ color: "#B30012" }}>
										{passwordErrors.newPasswordDifferentFromConfirmMessage}
									</Typography>
								)
							}
						/>
					</Box>

					<Box
						flex={1}
						display={"flex"}
						alignItems={"center"}
						justifyContent={"center"}
						padding={3}
						gridColumnGap={16}
					>
						<Button
							onClick={onClose}
							color="primary"
							variant="outlined"
							disabled={loading}
							fullWidth
							className={styles.button}
						>
							{ls.cancel}
						</Button>
						<Button
							onClick={handleSubmit}
							color="primary"
							variant="contained"
							fullWidth
							className={styles.button}
							disabled={
								Object.values(passwordErrors).some(Boolean) ||
								!passwords.newPassword ||
								!passwords.confirmPassword ||
								!passwords.oldPassword ||
								loading
							}
						>
							{loading ? <CircularProgress size={20} /> : ls.save}
						</Button>
					</Box>
				</Box>
			</Drawer>
		</>
	);
};

export default ChangePasswordDrawer;
