import { Button, IconButton } from "@material-ui/core";
import LoadOnScroll from "Components/LoadOnScroll";
import { modalConfirmation } from "Components/Modals/ModalConfirmation";
import Report from "Components/Report";
import DateSelectFilter from "Components/Report/DateSelectorFilter";
import { exportExcelRechargeHeadquarter } from "Components/Report/export";
import Spinner from "Components/Spinner";
import ModalLogs from "Components/views/Dashboard/Business/Detail/TreasuryAccountTransfer/ModalLogs";
import ModalReprocess from "Components/views/Dashboard/Business/Detail/TreasuryAccountTransfer/ModalReprocess";
import ModalCreateTransfer from "Components/views/Dashboard/WiipoFlex/TreasuryAccount/CreateTransfer/ModalCreateTransfer";
import dayjs from "dayjs";
import { isInRole } from "helpers/auth";
import { verifyIsAbleToDownloadZipReport } from "helpers/rechargeZipReportRules";
import { toStringCurrency } from "helpers/string";
import { toastError, toastHTML, toastWarning } from "helpers/toast";
import ls from "Localization";
import _ from "lodash";
import { statusTransaction, typesTransaction } from "models/enumTypes";
import { BusinessData } from "models/interfaces/businessData";
import { IUseRefFilter } from "models/interfaces/components/Business/importValueRecharge";
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from "react";
import { AiOutlineFileExcel, AiOutlineFilePdf } from "react-icons/ai";
import { FaFileImport, FaPlus } from "react-icons/fa";
import { GrDocumentZip } from "react-icons/gr";
import { IoRefreshOutline } from "react-icons/io5";
import { MdClear, MdReplay } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { importTransactionsMultipleCNPJ } from "store/actions/accountHolderTransaction/create";
import { IAccountHolderTransactionState } from "store/actions/accountHolderTransaction/types";
import { getVerifyBalanceAccountTransfer } from "store/actions/balanceAccount/transfer";
import { getTransferForId } from "store/actions/business/report";
import {
	cancelTransfer,
	downloadTreasury,
	downloadTreasuryPdf,
	downloadTreasuryZip
} from "store/actions/treasuryAccountTransfer/cancel";
import {
	reprocessTransfer,
	setValue
} from "store/actions/treasuryAccountTransfer/create";
import {
	clearValues,
	getTransfers
} from "store/actions/treasuryAccountTransfer/report";
import { ITreasuryAccountTransferState } from "store/actions/treasuryAccountTransfer/types";
import { State } from "store/reducers";
import { initialStateLogin } from "store/reducers/login/types/interfaces";
import { workers } from "Worker";
import XLSX from "xlsx";

import TransactionTypeModal from "../../../../../Components/views/Dashboard/Business/Detail/TreasuryAccountTransfer/TransactionTypeModal";
import ImportTATransfers from "./Import";
import ImportTATransfersBusiness, {
	parseImportError
} from "./Import/ModalBusiness";

interface Props {
	id: string;
	load: Function;
	business: BusinessData;
	isHeadquarters: boolean;
}

let debounceImportTransactionsMultipleCNPJ: (() => void) & _.Cancelable = null;

const TreasuryAccountTransfer: React.FC<Props> = ({
	id,
	business,
	load: loadBusiness,
	isHeadquarters
}) => {
	const dispatch = useDispatch();

	const handleChange = useCallback(
		(name, value) => {
			dispatch(
				setValue({
					[name]: value
				})
			);
		},
		[dispatch]
	);

	const [showModalAdd, setShowModalAdd] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);

	const [isDeposit, setIsDeposit] = useState(false);
	const [loadingFile, setLoadingFile] = useState(false);
	const [loadingAll, setLoadingAll] = useState(false);
	const [totalRechargeValue, setTotalRechargeValue] = useState(0);

	const handleOnLoadAll = useCallback(() => setLoadingAll(true), []);

	const handleOffLoadAll = useCallback(() => setLoadingAll(false), []);

	const {
		items,
		pages,
		loading,
		importTATransfersData,
		showModalBusiness,
		showModalType,
		showModalUser
	} = useSelector<State, ITreasuryAccountTransferState>(
		s => s.treasuryAccountTransfer
	);

	const inputRef = useRef<HTMLInputElement>(null);
	const filterProps = useRef<IUseRefFilter>({
		skip: 0,
		take: 10,
		filter: { "filters[businessId]": id }
	});

	const transactionsDebounced = useRef<(() => void) & _.Cancelable>(null);

	const [openModalLogs, setOpenModalLogs] = useState(false);
	const [openModalReprocessing, setOpenModalReprocessing] = useState(false);
	const [treasuryAccountTransferId, setTreasuryAccountTransferId] =
		useState("");
	const [textModalReprocessing, setTextModalReprocessing] = useState("");
	const [hasBranchs, setHasBranches] = useState(false);
	const [arrayItems, setArrayItems] = useState([]);

	const changeTotalRechargeValue = useCallback((value: number) => {
		setTotalRechargeValue(value);
	}, []);
	const changeHasBranches = useCallback((value: boolean) => {
		setHasBranches(value);
	}, []);

	const handleCloseModalLogs = useCallback(() => {
		setOpenModalLogs(false);
	}, []);
	const handleOpenCloseModals = useCallback(
		(
			shouldOpenModalTransferTypeModal = false,
			shouldOpenModalUser = false,
			notOpenModals = false,
			shouldOpenBusinessModal = false
		) => {
			if (shouldOpenModalTransferTypeModal) {
				handleChange("showModalType", true);
				handleChange("showModalBusiness", false);
				handleChange("showModalUser", false);
				setLoadingFile(false);
				handleOffLoadAll();
			}
			if (shouldOpenModalUser) {
				handleChange("showModalType", false);
				handleChange("showModalBusiness", false);
				handleChange("showModalUser", true);
				setLoadingFile(false);
				handleOffLoadAll();
			}
			if (notOpenModals) {
				handleChange("showModalBusiness", false);
				handleChange("showModalType", false);
				handleChange("showModalUser", false);
			}

			if (shouldOpenBusinessModal) {
				handleChange("showModalBusiness", true);
				handleChange("showModalType", false);
				handleChange("showModalUser", false);
				setLoadingFile(false);
				handleOffLoadAll();
			}
		},
		[handleChange, handleOffLoadAll]
	);

	const handleCloseModalReprocessing = useCallback(() => {
		setOpenModalReprocessing(false);
	}, []);

	const handleExport = data => {
		exportExcelRechargeHeadquarter(data);
	};

	const { user } = useSelector<State, initialStateLogin>(s => s.login);

	const { loading: loadingImport, insufficientFunds } = useSelector<
		State,
		IAccountHolderTransactionState
	>(s => s.accountHolderTransaction);

	const shouldDisableTreasury = useMemo(() => {
		const findType = importTATransfersData?.find(i => i?.type === 1);

		if (findType) {
			return false;
		}
		if (isHeadquarters) return insufficientFunds;

		return business?.treasuryAccountBalance <= totalRechargeValue;
	}, [
		business?.treasuryAccountBalance,
		importTATransfersData,
		insufficientFunds,
		isHeadquarters,
		totalRechargeValue
	]);

	const isAdmin = isInRole(user, ["admin"]);
	const isWiipoFlex = isInRole(user, ["Admin Wiipo Flex"]);
	const isAbleToDownloadZipReport = verifyIsAbleToDownloadZipReport(id);

	const table = useRef(null);

	useEffect(() => {
		dispatch(clearValues());

		filterProps.current = {
			skip: 0,
			take: 10,
			filter: { "filters[businessId]": id }
		};

		if (isLoaded) load();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	const itemsArray = useMemo(() => {
		if (arrayItems && arrayItems.length > 0) {
			return arrayItems;
		}

		if (items && items.length > 0) {
			return items;
		}

		return [];
	}, [arrayItems, items]);

	const editArrayItems = useCallback(
		(id, value) => {
			const newArray = [...itemsArray];

			const findItemIndex = newArray.findIndex(i => i.id === id);

			if (findItemIndex !== -1) {
				const updatedItem = { ...newArray[findItemIndex] };

				updatedItem.value = value;

				newArray[findItemIndex] = updatedItem;
				setArrayItems(newArray);
			}
		},
		[itemsArray]
	);

	const handleGetTransactionId = useCallback(
		id => {
			dispatch(
				getTransferForId(id, (err, model) => {
					if (err) {
						toastError(err);
					} else {
						editArrayItems(model.id, model.value);
					}
				})
			);
		},
		[dispatch, editArrayItems]
	);

	const load = useCallback(() => {
		dispatch(
			getTransfers({
				...filterProps.current
			})
		);
		setIsLoaded(true);
	}, [dispatch]);

	const loadColumnsData = useCallback(
		data => {
			handleOnLoadAll();
			const models = [];
			const dataFilterNull = data.filter(i => i);

			for (const d of dataFilterNull) {
				let total = 0;

				for (const key in d) {
					if (Number.isNaN(Number(key)) || !Number(d[key])) continue;

					models.push({
						document: d.document,
						type: d.type,
						balanceAccountType: Number(key),
						value: Math.round(Number(d[key]) * 100),
						description: ""
					});
					total += Math.round(Number(d[key]) * 100);
				}

				if (Math.round(d.total * 100) !== total) {
					handleOffLoadAll();
					return toastError(
						`Valor total(${d.total}) de ${d.name}(${
							d.document
						}) diferente do somatório dos bolsos(${total / 100.0})`
					);
				}
			}

			if (debounceImportTransactionsMultipleCNPJ)
				debounceImportTransactionsMultipleCNPJ.cancel();

			debounceImportTransactionsMultipleCNPJ = _.debounce(() => {
				dispatch(
					importTransactionsMultipleCNPJ(
						id,
						models,
						business?.treasuryAccountBalance,
						(err, model, params) => {
							if (err) {
								handleOffLoadAll();
								if (params?.cnpjsMissingValue) {
									toastHTML(
										<div
											style={{
												display: "flex",
												flexDirection: "column",
												justifyContent: "center",
												alignItems: "center",
												width: "100%"
											}}
										>
											{params?.headquartersMissingValue > 0 ? (
												<p style={{ margin: 0, padding: 0 }}>
													Na matriz está faltando{" "}
													{toStringCurrency(
														params?.headquartersMissingValue / 100
													)}
												</p>
											) : (
												<></>
											)}
											{params?.cnpjsMissingValue > 0 && (
												<>
													<p
														style={{
															margin: 0,
															padding: 0,
															paddingTop: 5
														}}
													>
														Algumas filiais não tem saldo
													</p>
													<p
														style={{
															margin: 0,
															padding: 0,
															paddingBottom: 5
														}}
													>
														suficiente para executar a recarga.
													</p>
													<Button
														variant="contained"
														style={{
															height: 20,
															width: 120,
															fontSize: 11,
															marginTop: 5,
															background: "#fff",
															color: "red"
														}}
														onClick={() =>
															handleExport(params?.cnpjsMissingValue)
														}
													>
														Baixar log
													</Button>
												</>
											)}
										</div>
									);
									handleOpenCloseModals(true);
								} else if (err.default) toastError(err.default);
								else if (typeof err === "string") {
									toastError(err);
								} else if (
									err.user_not_found ||
									err.mothers_name_or_birthdate_not_found
								) {
									err.user_not_found = err._original.user_not_found;

									let errors = [];

									Object.keys(err._original).map(item => {
										let errUser = err._original[item];
										try {
											if (typeof err._original[item] === "string")
												errUser = JSON.parse(err._original[item]);
										} catch (error) {
											toastError(JSON.stringify(error));
										}

										for (const cpf of errUser) {
											let error: any = {
												cpf,
												description:
													ls[parseImportError[item] || "ErrorUserDesconhecido"]
											};

											errors.push(error);
										}
										return item;
									});

									handleChange("returnErrors", errors);
								} else {
									toastError(JSON.stringify(err));
								}
							} else if (model.length === 0) {
								handleChange("model", []);
								handleOffLoadAll();
								handleOpenCloseModals(true);
							} else {
								changeHasBranches(true);
								handleChange("model", model);
								handleOffLoadAll();
								handleOpenCloseModals(true);
							}
						}
					)
				);
			}, 500);

			debounceImportTransactionsMultipleCNPJ();
		},
		[
			business?.treasuryAccountBalance,
			changeHasBranches,
			dispatch,
			handleChange,
			handleOffLoadAll,
			handleOnLoadAll,
			handleOpenCloseModals,
			id
		]
	);

	const handleFetchData = useCallback(
		tableState => {
			if (!isLoaded) {
				return;
			}

			if (transactionsDebounced.current) {
				transactionsDebounced.current.cancel();
			}

			let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

			const [sort] = sorted || [{ id: "", desc: true }];

			let creationDate = filtered.find(c => c.id === "creationDate");

			if (creationDate?.value) {
				filtered = filtered.filter(c => c.id !== "creationDate");
				let date = null;

				if (creationDate?.value.startDate || creationDate?.value.endDate) {
					date = {
						startAt: creationDate.value.startDate,
						endAt: creationDate.value.endDate
					};
				}

				if (date) {
					filtered.push({
						id: "creationDate",
						value: JSON.stringify(date)
					});
				}
			}

			let updateDate = filtered.find(c => c.id === "updateDate");

			if (updateDate?.value) {
				filtered = filtered.filter(c => c.id !== "updateDate");
				let date = null;

				if (updateDate?.value.startDate || updateDate?.value.endDate) {
					date = {
						startAt: updateDate.value.startDate,
						endAt: updateDate.value.endDate
					};
				}

				if (date) {
					filtered.push({
						id: "updateDate",
						value: JSON.stringify(date)
					});
				}
			}

			let sendAt = filtered.find(c => c.id === "sendAt");

			if (sendAt?.value) {
				filtered = filtered.filter(c => c.id !== "sendAt");
				let date = null;

				if (sendAt?.value.startDate || sendAt?.value.endDate) {
					date = {
						startAt: sendAt.value.startDate,
						endAt: sendAt.value.endDate
					};
				}

				if (date) {
					filtered.push({
						id: "sendAt",
						value: JSON.stringify(date)
					});
				}
			}

			const filters = { "filters[businessId]": id };

			filtered.forEach(item => {
				filters[`filters[${item.id}]`] = item.value;
			});

			filterProps.current = {
				skip: page * pageSize,
				take: pageSize,
				filter: filters,
				sort: sort.id,
				desc: sort.desc,
				toExport,
				callback
			};

			transactionsDebounced.current = _.debounce(() => load(), 500);

			transactionsDebounced.current();
		},
		[isLoaded, id, load]
	);

	const handleDownloadTreasury = useCallback(
		id => {
			dispatch(downloadTreasury(id));
		},
		[dispatch]
	);

	const handleDownloadTreasuryPdf = useCallback(
		id => {
			dispatch(downloadTreasuryPdf(id));
		},
		[dispatch]
	);

	const handleDownloadTreasuryZip = useCallback(
		id => {
			dispatch(downloadTreasuryZip(id));
		},
		[dispatch]
	);

	const handleCancel = useCallback(
		id => {
			modalConfirmation({
				onClickNo: () => {},
				onClickYes: () => {
					dispatch(
						cancelTransfer(id, err => {
							if (err) {
								toastError(err);
							} else {
								load();
							}
						})
					);
				},
				title: ls.confirm,
				body: <>{ls.formatString(ls.treasuryAccountConfirmationCancel)}?</>
			});
		},
		[dispatch, load]
	);

	const dispatchReprocessing = useCallback(() => {
		dispatch(
			reprocessTransfer(treasuryAccountTransferId, id, err => {
				if (err) {
					toastError(err);
				} else {
					handleCloseModalReprocessing();
					load();
				}
			})
		);
	}, [
		dispatch,
		handleCloseModalReprocessing,
		id,
		load,
		treasuryAccountTransferId
	]);

	const handleReprocessRecharge = useCallback(
		treasuryAccountTransferId => {
			dispatch(
				getVerifyBalanceAccountTransfer({
					businessId: id,
					treasuryAccountTransferId,
					callback: (err, model) => {
						if (err) toastError(err);
						else {
							setOpenModalReprocessing(true);
							setTreasuryAccountTransferId(treasuryAccountTransferId);
							if (model === 0) {
								setTextModalReprocessing(
									`${ls.formatString(ls.treasuryAccountConfirmationReprocess)}`
								);
							} else {
								setTextModalReprocessing(
									`A recarga que você está tentando reprocessar tem ${model} usuários que já receberam esse mesmo valor, no mesmo bolso recentemente. Deseja continuar mesmo assim?`
								);
							}
						}
					}
				})
			);
		},
		[dispatch, id]
	);

	const calcSaleAfter = useCallback(
		(type: number, saleBefore: number, value: number) => {
			if (type === 0 || type === 2) {
				return saleBefore + value;
			}

			return saleBefore - value;
		},
		[]
	);

	const columns = useMemo(() => {
		const businessColumns = [
			{
				Header: ls.id.toUpperCase(),
				id: "id",
				accessor: c => c.id,
				defaultSortDesc: true,
				show: false,
				width: 140
			},
			{
				Header: ls.creationDate,
				id: "creationDate",
				accessor: c =>
					dayjs(c.creationDate).format(ls.dateFormatWithoutSeconds),
				defaultSortDesc: true,
				width: 180,
				show: true,
				sortMethod: (a, b, _desc: boolean) => {
					let aD = dayjs(a, ls.dateFormatWithoutSeconds);
					let bD = dayjs(b, ls.dateFormatWithoutSeconds);

					if (aD.isSame(bD)) {
						return 0;
					}

					return aD.isAfter(bD) ? 1 : -1;
				},
				Filter: DateSelectFilter
			},
			{
				Header: ls.createdBy,
				id: "createdBy",
				show: true,
				accessor: c => c.createdBy.fullName
			},

			{
				Header: ls.valueToRecharge,
				id: "valueOriginal",
				show: true,
				accessor: c => toStringCurrency(c.valueOriginal / 100)
			},
			{
				Header: ls.valueProcessed,
				id: "value",
				show: true,
				accessor: c => (
					<>
						{toStringCurrency(c.value / 100)}
						{(c.status === 0 || c.status === 5) && c.type === 1 && (
							<IconButton
								onClick={() => {
									setArrayItems(items);
									handleGetTransactionId(c.id);
								}}
								style={{
									background: "#5734DA",
									borderRadius: 4,
									width: 22,
									height: 22,
									marginLeft: 4
								}}
								size="small"
							>
								<IoRefreshOutline size={20} color="#ffffff" />
							</IconButton>
						)}
					</>
				)
			},
			{
				Header: ls.saleBefore,
				id: "treasuryBalanceBefore",
				accessor: c =>
					c.balanceBefore || c.balance === 0
						? toStringCurrency(c.balanceBefore / 100)
						: "-",
				width: 160,
				show: true,
				filterable: false
			},
			{
				Header: ls.saleAfter,
				id: "treasuryBalanceBeforeAfter",
				accessor: c =>
					c.balanceBefore || c.balance === 0
						? toStringCurrency(
								calcSaleAfter(c.type, c.balanceBefore, c.value) / 100
						  )
						: "-",
				width: 160,
				show: true,
				filterable: false
			},
			{
				Header: ls.type,
				id: "type",
				show: true,
				accessor: c => typesTransaction[c.type],
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%" }}
						value={filter ? filter.value : ""}
					>
						<option value="">{ls.all}</option>
						{Object.keys(typesTransaction).map(item => (
							<option value={item} key={item}>
								{typesTransaction[item]}
							</option>
						))}
					</select>
				)
			},
			{
				Header: ls.status,
				id: "status",
				show: true,
				accessor: c =>
					String(c.status) === "4" ? (
						<span style={{ color: "red" }}>{statusTransaction[c.status]}</span>
					) : (
						statusTransaction[c.status]
					),
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%" }}
						value={filter ? filter.value : ""}
					>
						<option value="">{ls.all}</option>
						{Object.keys(statusTransaction).map(item => (
							<option value={item} key={item}>
								{statusTransaction[item]}
							</option>
						))}
					</select>
				)
			},
			{
				Header: ls.dateRecharge,
				id: "updateDate",
				show: true,
				accessor: c =>
					c.sendAt
						? dayjs(c.sendAt).format(ls.dateFormatWithoutSeconds)
						: dayjs(c.updateDate).format(ls.dateFormatWithoutSeconds),
				width: 180,
				sortMethod: (a, b, _desc: boolean) => {
					let aD = dayjs(a, ls.dateFormatWithoutSeconds);
					let bD = dayjs(b, ls.dateFormatWithoutSeconds);

					if (aD.isSame(bD)) {
						return 0;
					}

					return aD.isAfter(bD) ? 1 : -1;
				},
				Filter: DateSelectFilter
			},
			{
				Header: "Depósito atrelado",
				id: "fundsCode",
				accessor: c => c?.funds?.code,
				defaultSortDesc: true,
				show: false,
				width: 140
			},
			{
				Header: ls.actions,
				id: "actions",
				show: true,
				width: 130,
				accessor: c => (
					<>
						{c.type === 1 && ![0, 3].includes(c.status) && (
							<div
								style={{
									display: "flex",
									justifyContent: "space-around",
									alignItems: "center",
									flex: 1,
									cursor: "pointer",
									marginLeft: 2,
									marginRight: 2
								}}
								title="Baixar logs"
							>
								<AiOutlineFileExcel
									size={24}
									onClick={() => handleDownloadTreasury(c.id)}
								/>
							</div>
						)}
						{c.type === 1 && [1, 3, 4].includes(c.status) && (
							<div
								style={{
									display: "flex",
									justifyContent: "space-around",
									alignItems: "center",
									flex: 1,
									cursor: "pointer",
									marginLeft: 2,
									marginRight: 2
								}}
								title="Baixar PDF"
							>
								<AiOutlineFilePdf
									size={24}
									onClick={() => handleDownloadTreasuryPdf(c.id)}
								/>
							</div>
						)}
						{isAbleToDownloadZipReport &&
							c.type === 1 &&
							[1, 3, 4].includes(c.status) && (
								<div
									style={{
										display: "flex",
										justifyContent: "space-around",
										alignItems: "center",
										flex: 1,
										cursor: "pointer",
										marginLeft: 2,
										marginRight: 2
									}}
									title="Baixar Relatório por Centro de Custo"
								>
									{loading ? (
										<Spinner size={20} color="inherit" />
									) : (
										<GrDocumentZip
											size={20}
											onClick={() => handleDownloadTreasuryZip(c.id)}
										/>
									)}
								</div>
							)}
						{c.status === 3 && (
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									flex: 1,
									cursor: "pointer",
									marginLeft: 2,
									marginRight: 2
								}}
								title="Cancelar envio"
							>
								<MdClear size={24} onClick={() => handleCancel(c.id)} />
							</div>
						)}
						{c.status === 4 && (
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									flex: 1,
									cursor: "pointer",
									marginLeft: 2,
									marginRight: 2
								}}
								title="Tentar novamente"
							>
								<MdReplay
									size={24}
									onClick={() => handleReprocessRecharge(c.id)}
								/>
							</div>
						)}
					</>
				)
			}
		];

		if (isHeadquarters) {
			businessColumns.splice(1, 0, {
				Header: ls.business,
				id: "business",
				accessor: c => c.business.displayName,
				show: true
			});
		}

		return businessColumns;
	}, [
		isHeadquarters,
		items,
		handleGetTransactionId,
		calcSaleAfter,
		isAbleToDownloadZipReport,
		loading,
		handleDownloadTreasury,
		handleDownloadTreasuryPdf,
		handleDownloadTreasuryZip,
		handleCancel,
		handleReprocessRecharge
	]);

	const handleFilesChange = useCallback(
		async (e: React.ChangeEvent<HTMLInputElement>) => {
			try {
				handleOpenCloseModals(false, false, true);
				handleOnLoadAll();
				const file = e.target.files[0];
				e.preventDefault();

				const reader = new FileReader();
				const rABS = !!reader.readAsBinaryString;

				reader.onload = async e => {
					/* Parse data */
					const bstr = e.target.result;
					const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });

					/* Get first worksheet */
					const wsName = wb.SheetNames[0];
					const sheet = wb.Sheets[wsName];

					let dataResult = [];

					workers.postMessage({ action: "processData", file: sheet });

					workers.onmessage = async e => {
						dataResult = e.data;

						if (dataResult.length > 0) {
							const existBagWithdrawAndClub = dataResult?.filter(
								i => i && ((i["19"] && i["19"] > 0) || (i["0"] && i["0"] > 0))
							);

							if (existBagWithdrawAndClub.length > 0) {
								const filterBagWithdrawAndClub = dataResult?.map(i => ({
									...i,
									"19": 0,
									"0": 0,
									total: i.total - Number(i["19"] || 0) - Number(i["0"] || 0)
								}));

								const totalRecharge = filterBagWithdrawAndClub?.reduce(
									(a, b) => a + b.total,
									0
								);

								toastWarning(
									`Não é possível fazer recargas para o bolso Saque e Clube Wiipo, ${
										totalRecharge === 0
											? "por isso cancelamos a transação para esse bolso"
											: "por isso cancelamos a transação para esse bolso e o restante da carga ocorrerá normalmente."
									}`
								);

								if (totalRecharge > 0) {
									handleChange(
										"importTATransfersData",
										filterBagWithdrawAndClub.filter(i => i)
									);
									if (
										filterBagWithdrawAndClub.find(i => Number(i?.type) === 1)
									) {
										handleOpenCloseModals(false, true);
									} else if (isHeadquarters) {
										loadColumnsData(filterBagWithdrawAndClub.filter(i => i));
									} else {
										handleOpenCloseModals(true);
									}
								}
							} else {
								handleChange(
									"importTATransfersData",
									dataResult.filter(i => i)
								);

								if (dataResult.find(i => Number(i?.type) === 1)) {
									handleOpenCloseModals(false, true);
								} else if (isHeadquarters) {
									loadColumnsData(dataResult);
								} else {
									handleOpenCloseModals(true);
								}
							}
						} else {
							toastError("Há algum erro na planilha, não encontramos dados !");
						}

						setLoadingFile(false);
					};
				};

				if (rABS) reader.readAsBinaryString(file);
				else reader.readAsArrayBuffer(file);

				e.target.value = null;
			} catch (error) {
				toastError(`${error}`);
			}
		},
		[
			handleChange,
			handleOnLoadAll,
			handleOpenCloseModals,
			isHeadquarters,
			loadColumnsData
		]
	);

	const handleFileSelection = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setLoadingFile(false);
			handleOpenCloseModals(true);
			handleFilesChange(e);
		},
		[handleFilesChange, handleOpenCloseModals]
	);

	const onClickDeposit = useCallback(() => {
		setIsDeposit(true);
		setLoadingFile(false);
		handleChange("showModalUser", true);
	}, [handleChange]);

	const onClickTreasury = useCallback(() => {
		setIsDeposit(false);
		setLoadingFile(false);
		if (isHeadquarters && hasBranchs) handleChange("showModalBusiness", true);
		else handleChange("showModalUser", true);
	}, [isHeadquarters, hasBranchs, handleChange]);

	const handleTATransfers = useCallback(() => {
		inputRef.current.click();
	}, []);

	return (
		<>
			<ModalCreateTransfer
				open={showModalAdd}
				defaultValue={business}
				handleClose={success => {
					if (success) {
						load();
						loadBusiness();
					}

					setShowModalAdd(false);
				}}
			/>
			<LoadOnScroll load={load}>
				<Report
					manual
					tableRef={table}
					title={ls.treasuryAccountTransfer}
					data={itemsArray}
					showExport
					pages={pages}
					loading={loading}
					onFetchData={handleFetchData}
					filterable
					visibleColumns={columns.filter(c => c.show).map(c => c.Header)}
					headerRightComponent={
						<>
							{(isAdmin || isWiipoFlex) && (
								<>
									<a
										href="https://docs.google.com/spreadsheets/d/104kNojTFxMPkt48CBmZBpiHw9IfHanb6ZApLCv8GA74/edit?usp=sharing"
										target="_blank"
										rel="noopener noreferrer"
										style={{ marginRight: 16 }}
									>
										Baixar modelo de importação
									</a>
									{isHeadquarters && (
										<div
											style={{
												display: "flex",
												justifyContent: "space-around",
												alignItems: "center",
												flex: 1,
												cursor: "pointer"
											}}
											title="Baixar logs"
										>
											<AiOutlineFileExcel
												size={28}
												onClick={() => {
													setOpenModalLogs(true);
												}}
											/>
										</div>
									)}
									<IconButton
										onClick={handleTATransfers}
										disabled={loadingImport}
									>
										{loadingImport || loadingAll || loadingFile ? (
											<Spinner color="secondary" size={16} />
										) : (
											<FaFileImport />
										)}
									</IconButton>
								</>
							)}
							{isAdmin && (
								<IconButton
									color="primary"
									onClick={() => setShowModalAdd(true)}
								>
									<FaPlus />
								</IconButton>
							)}
						</>
					}
					defaultFilterMethod={(filter, row) =>
						String(row[filter.id])
							.toLowerCase()
							.indexOf(filter.value.toLowerCase()) > -1
					}
					columns={columns}
					defaultSorted={[
						{
							id: "creationDate",
							desc: true
						}
					]}
				/>
			</LoadOnScroll>
			{openModalLogs && (
				<ModalLogs
					id={id}
					open={openModalLogs}
					handleClose={handleCloseModalLogs}
					load={load}
				/>
			)}

			{showModalUser && (
				<ImportTATransfers
					id={id}
					isHeadquarters={isHeadquarters}
					open={showModalUser}
					items={importTATransfersData}
					updateTotalRechargeValue={changeTotalRechargeValue}
					handleClose={handleOpenCloseModals}
					shouldDisableTreasury={shouldDisableTreasury}
					isAdmin={isAdmin}
					isTreasury={Boolean(!isDeposit && !shouldDisableTreasury)}
					isDeposit={isDeposit}
					load={load}
				/>
			)}

			{showModalBusiness && (
				<ImportTATransfersBusiness
					id={id}
					open={showModalBusiness}
					handleClose={handleOpenCloseModals}
				/>
			)}

			{openModalReprocessing && (
				<ModalReprocess
					open={openModalReprocessing}
					handleClose={handleCloseModalReprocessing}
					text={textModalReprocessing}
					clickOk={dispatchReprocessing}
				/>
			)}

			<input
				ref={inputRef}
				style={{ display: "none" }}
				type="file"
				accept="application/excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				onChange={handleFileSelection}
			/>

			{showModalType ? (
				<TransactionTypeModal
					handleClose={handleOpenCloseModals}
					loadingFile={loadingFile}
					onClickDeposit={onClickDeposit}
					shouldDisableTreasury={shouldDisableTreasury}
					onClickTreasury={onClickTreasury}
					openTransferTypeModal={showModalType}
				/>
			) : (
				<> </>
			)}
		</>
	);
};

export default TreasuryAccountTransfer;
