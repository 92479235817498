import {
	AppBar,
	Badge,
	Box,
	Collapse,
	CssBaseline,
	Drawer,
	Grow,
	IconButton,
	List,
	Menu,
	MenuItem,
	Toolbar,
	Typography
} from "@material-ui/core";
import clsx from "clsx";
import ChangePasswordDrawer from "Components/ChangePasswordDrawer";
import TooltipLight from "Components/TooltipLight";
import ModalNotification from "Components/views/Dashboard/Header/ModalNotification";
import { isInRole } from "helpers/auth";
import { verifyOpenRequestCards } from "helpers/openRequestCards";
import { toastError } from "helpers/toast";
import LogRocket from "logrocket";
import { ModerationCategory } from "models/moderationCategory";
import { ModerationStatus } from "models/moderationStatus";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DiStreamline } from "react-icons/di";
import { FaCoins, FaStamp, FaUserSlash } from "react-icons/fa";
import { GiFiles, GiMegaphone } from "react-icons/gi";
import {
	MdAccountCircle,
	MdAttachMoney,
	MdBusiness,
	MdCreditCard,
	MdExpandLess,
	MdExpandMore,
	MdHeadsetMic,
	MdHistory,
	MdHome,
	MdLocalMall,
	MdMenu,
	MdMoreHoriz,
	MdNotifications,
	MdPaid,
	MdPeople,
	MdShoppingBasket,
	MdStore,
	MdTextSnippet
} from "react-icons/md";
import { TbPig } from "react-icons/tb";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getModerations } from "store/actions/moderation/report";

import ls from "../../../Localization";
import * as actions from "../../../store/actions/header";
import { getFundsHistory } from "../../../store/actions/header/notificationsUser";
import * as loginActions from "../../../store/actions/login";
import Item from "./item";
import useStyles from "./styles";

const AuthItem = ({ user, needRoles, ...rest }: any) => {
	if (isInRole(user, needRoles)) return <Item {...rest} />;

	return null;
};

const AuthCollapse = ({ user, needRoles, ...rest }: any) => {
	if (isInRole(user, needRoles)) return <Collapse {...rest} />;

	return null;
};

export function RenderHeader(props) {
	const dispatch = useDispatch();
	const moderations = useSelector<any, any>(c => c.moderationReport);

	const classes = useStyles({});
	const [anchorEl, setAnchorEl] = useState(null);
	const [moderationsPending, setModerationsPending] = useState(0);
	const [openChangePasswordDrawer, setOpenChangePasswordDrawer] =
		useState(false);
	const {
		children,
		setValue,
		login: { user },
		state: {
			openMenu,
			openAccount,
			openUsers,
			openNotification,
			openWiipoFlex,
			openWiipoClub,
			openMore
		}
	} = props;

	const handleChange = useCallback(
		(id: string, value: any) => {
			setValue({ [id]: value });
		},
		[setValue]
	);

	useEffect(() => {
		const moderationsPending = moderations?.items?.filter(
			moderation =>
				[
					ModerationCategory.Order,
					ModerationCategory.ConsumptionLimit
				].includes(moderation.category) &&
				moderation.status === ModerationStatus.Pending
		).length;

		setModerationsPending(moderationsPending);
	}, [moderations]);

	useEffect(() => {
		if (props.history.location.pathname.indexOf("moderation") === -1)
			dispatch(getModerations());

		dispatch(
			getFundsHistory(err => {
				if (err) toastError(err);
			})
		);

		const updateDimensions = () => {
			handleChange("openMenu", window.innerWidth > 1200);
		};

		window.addEventListener("resize", updateDimensions);
		return () => {
			window.removeEventListener("resize", updateDimensions);
		};
	}, [dispatch, handleChange]);

	const handleToggle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		if (!openAccount) setAnchorEl(e.currentTarget);
		else setAnchorEl(null);

		handleChange("openAccount", !openAccount);
	};

	const handleOpenMoviDesk = useCallback(() => {
		window.open("https://wiipo.movidesk.com/form/8973/", "_blank");
	}, []);

	const handleOpenNotification = useCallback(() => {
		handleChange("openNotification", true);
	}, [handleChange]);

	const handleCloseNotification = useCallback(() => {
		handleChange("openNotification", false);
	}, [handleChange]);

	const handleClose = () => {
		setAnchorEl(null);
		handleChange("openAccount", false);
	};

	const handleLogout = () => {
		handleClose();

		props.logout();
	};

	const handleDrawerOpen = () => {
		handleChange("openMenu", !openMenu);
	};

	useEffect(() => {
		if (user && process.env.REACT_APP_LOG_ROCKET)
			LogRocket.identify(user.id, {
				name: user.fullName,
				email: user.email
			});
	}, [user]);

	const goToMyCompany = () => {
		handleClose();
		props.history.push(`/Company/${user.business.businessId}`);
	};

	const togglePasswordDrawer = () => {
		setOpenChangePasswordDrawer(!openChangePasswordDrawer);
	};

	const menuId = "primary-search-account-menu";

	const isAdmin = isInRole(user, ["admin"]);

	const { isCompany, hasWiipoFlex, isHeadquarters } =
		user?.business?.business || {};

	const renderWiipoflexItem = useMemo(() => {
		if (hasWiipoFlex || isAdmin) {
			return (
				<AuthItem
					key={17}
					user={user}
					needRoles={["Admin", "Admin Wiipo Flex", "Business Admin"]}
					classes={classes}
					icon={<MdCreditCard size={24} />}
					onClick={() => handleChange("openWiipoFlex", !openWiipoFlex)}
					text="Wiipo Flex"
				>
					{openWiipoFlex ? <MdExpandLess /> : <MdExpandMore />}
				</AuthItem>
			);
		}

		return <></>;
	}, [classes, handleChange, hasWiipoFlex, isAdmin, openWiipoFlex, user]);

	const renderWiipoflexCollapse = useMemo(() => {
		if (hasWiipoFlex || isAdmin) {
			return (
				<AuthCollapse
					key={18}
					user={user}
					needRoles={["Admin", "Admin Wiipo Flex", "Business Admin"]}
					in={openWiipoFlex}
					timeout="auto"
					unmountOnExit
				>
					<List component="div" disablePadding>
						<AuthItem
							key={9}
							user={user}
							needRoles={["Admin"]}
							classes={classes}
							nested
							to="/TransactionFlex"
							icon={<MdAttachMoney size={24} />}
							text={ls.transactions}
						/>

						<AuthItem
							key={9}
							user={user}
							needRoles={["Admin", "Admin Wiipo Flex", "Business Admin"]}
							classes={classes}
							nested
							to="/TreasuryAccountFlex"
							icon={<DiStreamline size={24} />}
							text={ls.treasury}
						/>

						{/* <AuthItem
							key={9}
							user={user}
							needRoles={["Admin", "Admin Wiipo Flex", "Business Admin"]}
							classes={classes}
							nested
							to="/Invoice"
							icon={<FaFileInvoice size={24} />}
							text={ls.invoice}
						/>
						*/}
						{verifyOpenRequestCards(user?.id) && (
							<AuthItem
								key={9}
								user={user}
								needRoles={["Admin"]}
								classes={classes}
								nested
								to="/RequestCards"
								icon={<MdCreditCard size={24} />}
								text={ls.cards}
							/>
						)}
					</List>
				</AuthCollapse>
			);
		}

		return <></>;
	}, [classes, hasWiipoFlex, isAdmin, openWiipoFlex, user]);

	const renderMenu = () => {
		if (!anchorEl) return null;

		return (
			<Menu
				anchorEl={anchorEl}
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
				id={menuId}
				keepMounted
				transformOrigin={{ vertical: "top", horizontal: "right" }}
				open={openAccount}
				onClose={handleClose}
			>
				{isAdmin && (
					<MenuItem onClick={handleClose}>
						<Link to="/Configuration">{ls.configuration}</Link>
					</MenuItem>
				)}
				<MenuItem onClick={goToMyCompany}>Minha empresa</MenuItem>
				<MenuItem onClick={togglePasswordDrawer}>{ls.changePassword}</MenuItem>
				<MenuItem onClick={handleLogout}>{ls.logout}</MenuItem>
			</Menu>
		);
	};

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar position="fixed" className={classes.appBar}>
				<Toolbar>
					{openMenu && (
						<Grow
							in={openMenu}
							style={{ transitionDelay: openMenu ? "100ms" : "0ms" }}
						>
							<img
								className={classes.logo}
								src="/img/logo_wiipo.png"
								alt="Wiipo"
								width="200"
							/>
						</Grow>
					)}
					{!openMenu && (
						<Grow
							in={!openMenu}
							style={{ transitionDelay: !openMenu ? "100ms" : "0ms" }}
						>
							<img
								className={classes.logo}
								src="/img/icon-wiipo.svg"
								alt="Wiipo"
								width="36"
							/>
						</Grow>
					)}
					<IconButton
						color="inherit"
						aria-label="Open drawer"
						onClick={handleDrawerOpen}
						className={classes.menuButton}
					>
						<MdMenu />
					</IconButton>
					<Typography className={classes.grow} />
					<div style={{ float: "right" }}>
						{isAdmin && (
							<IconButton
								edge="end"
								aria-label="account of current user"
								aria-controls={menuId}
								aria-haspopup="true"
								onClick={handleOpenNotification}
								style={{ marginRight: 3 }}
								color="inherit"
							>
								<MdNotifications />
							</IconButton>
						)}
						<TooltipLight title="Atendimento" placement="top">
							<IconButton
								edge="end"
								aria-label="account of current user"
								aria-controls={menuId}
								aria-haspopup="true"
								onClick={handleOpenMoviDesk}
								style={{ marginRight: 3 }}
								color="inherit"
							>
								<MdHeadsetMic />
							</IconButton>
						</TooltipLight>
						<IconButton
							edge="end"
							aria-label="account of current user"
							aria-controls={menuId}
							aria-haspopup="true"
							onClick={handleToggle}
							color="inherit"
						>
							<MdAccountCircle />
						</IconButton>
					</div>
				</Toolbar>
			</AppBar>
			<Drawer
				variant="permanent"
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: openMenu,
					[classes.drawerClose]: !openMenu
				})}
				classes={{
					paper: clsx({
						[classes.drawerOpen]: openMenu,
						[classes.drawerClose]: !openMenu
					})
				}}
				open={openMenu}
			>
				<div className={classes.toolbar} />
				<List>
					{[
						<AuthItem
							key={1}
							classes={classes}
							to="/"
							icon={<MdHome size={24} />}
							text={ls.home}
							user={user}
							needRoles={["Admin"]}
						/>,
						<>
							{process.env.REACT_APP_MODERATION_ALLOW_USERS_ID?.split(
								","
							)?.includes(user.id) && (
								<AuthItem
									key={2}
									classes={classes}
									to="/moderation"
									icon={
										<Badge
											badgeContent={moderationsPending || 0}
											color="secondary"
										>
											<FaStamp size={24} />
										</Badge>
									}
									text={ls.moderation}
									user={user}
									needRoles={["Admin", "Business Admin"]}
								/>
							)}
						</>,
						<AuthItem
							key={3}
							user={user}
							needRoles={["Admin"]}
							classes={classes}
							to="/Order"
							icon={<MdShoppingBasket size={24} />}
							text={ls.orders}
						/>,

						<AuthItem
							key={4}
							user={user}
							needRoles={["Admin", "Business Admin"]}
							classes={classes}
							to="/Transaction"
							icon={<MdAttachMoney size={24} />}
							text={ls.transactions}
						/>,

						<AuthItem
							key={15}
							user={user}
							needRoles={["Admin"]}
							classes={classes}
							icon={<TbPig size={24} />}
							onClick={() => handleChange("openWiipoClub", !openWiipoClub)}
							text="Clube Wiipo"
						>
							{openWiipoClub ? <MdExpandLess /> : <MdExpandMore />}
						</AuthItem>,

						<AuthCollapse
							key={16}
							user={user}
							needRoles={["Admin"]}
							in={openWiipoClub}
							timeout="auto"
							unmountOnExit
						>
							<List component="div" disablePadding>
								<AuthItem
									key={9}
									user={user}
									needRoles={["Admin", "Admin Wiipo Flex"]}
									classes={classes}
									nested
									to="/InstallmentClub"
									icon={<GiFiles size={24} />}
									text={ls.installments}
								/>
								<AuthItem
									key={10}
									user={user}
									needRoles={["Admin", "Admin Wiipo Flex"]}
									classes={classes}
									nested
									to="/TransactionClub"
									icon={<MdAttachMoney size={24} />}
									text={ls.transactions}
								/>
								<AuthItem
									key={23}
									user={user}
									needRoles={["Admin"]}
									classes={classes}
									nested
									to="/InvoicingClub"
									icon={<MdTextSnippet size={24} />}
									text={ls.invoicingClubeWiipoMenu}
								/>
								<AuthItem
									key={24}
									user={user}
									needRoles={["Admin"]}
									classes={classes}
									nested
									to="/CampaignClub"
									icon={<GiMegaphone size={24} />}
									text={ls.campaign}
								/>
							</List>
						</AuthCollapse>,
						renderWiipoflexItem,
						renderWiipoflexCollapse,

						<AuthItem
							key={5}
							user={user}
							needRoles={["Admin", "Business Admin"]}
							classes={classes}
							to="/Installment"
							icon={<GiFiles size={24} />}
							text={isAdmin ? ls.installments : ls.activity}
						/>,

						<AuthItem
							key={7}
							user={user}
							needRoles={["Admin"]}
							classes={classes}
							icon={<MdPeople size={24} />}
							onClick={() => handleChange("openUsers", !openUsers)}
							text="Rede"
						>
							{openUsers ? <MdExpandLess /> : <MdExpandMore />}
						</AuthItem>,

						<AuthCollapse
							key={8}
							user={user}
							needRoles={["Admin"]}
							in={openUsers}
							timeout="auto"
							unmountOnExit
						>
							<List component="div" disablePadding>
								<AuthItem
									key={9}
									user={user}
									needRoles={["Admin", "Business Admin"]}
									classes={classes}
									nested
									to="/Store"
									icon={<MdStore size={24} />}
									text={ls.stores}
								/>
								<AuthItem
									key={10}
									user={user}
									needRoles={["Admin", "Business Admin"]}
									classes={classes}
									nested
									to="/Company"
									icon={<MdBusiness size={24} />}
									text={ls.companies}
								/>
								<AuthItem
									key={11}
									user={user}
									needRoles={["Admin", "Business Admin"]}
									classes={classes}
									nested
									to="/Headquarters"
									icon={<MdBusiness size={24} />}
									text={ls.headquarters}
								/>
								<AuthItem
									key={12}
									user={user}
									needRoles={["Admin"]}
									classes={classes}
									nested
									to="/Franchise"
									icon={<MdBusiness size={24} />}
									text={ls.franchises}
								/>
								{isAdmin && (
									<AuthItem
										key={13}
										user={user}
										needRoles={["Admin"]}
										classes={classes}
										to="/User"
										nested
										icon={<MdPeople size={24} />}
										text={ls.users}
									/>
								)}
							</List>
						</AuthCollapse>,
						(isCompany || isHeadquarters) && !isAdmin ? (
							<AuthItem
								key={12}
								user={user}
								needRoles={["Business Admin", "Admin Wiipo Flex"]}
								classes={classes}
								to="/User"
								icon={<MdPeople size={24} />}
								text={ls.users}
							/>
						) : null,
						<AuthItem
							key={21}
							user={user}
							needRoles={["Admin", "Business Admin"]}
							classes={classes}
							icon={<MdMoreHoriz size={24} />}
							onClick={() => handleChange("openMore", !openMore)}
							text="Mais"
						>
							{openMore ? <MdExpandLess /> : <MdExpandMore />}
						</AuthItem>,

						<Collapse key={22} in={openMore} timeout="auto" unmountOnExit>
							<List component="div" disablePadding>
								<AuthItem
									key={23}
									user={user}
									needRoles={["Admin"]}
									classes={classes}
									nested
									to="/Marketing"
									icon={<GiMegaphone size={24} />}
									text="Marketing"
								/>
								<AuthItem
									key={24}
									user={user}
									needRoles={["Admin", "Business Admin"]}
									classes={classes}
									to="/Checkout"
									nested
									icon={<FaUserSlash size={24} />}
									text={ls.checkouts}
								/>
								<AuthItem
									key={25}
									user={user}
									needRoles={["Admin"]}
									classes={classes}
									nested
									to="/Cashback"
									icon={<MdLocalMall size={24} />}
									text={ls.cashback}
								/>
								<AuthItem
									key={26}
									user={user}
									needRoles={["Admin", "Business Admin"]}
									classes={classes}
									nested
									to="/Credit"
									icon={<FaCoins size={24} />}
									text={ls.credits}
								/>
								<AuthItem
									key={27}
									user={user}
									needRoles={["Admin", "Business Admin"]}
									classes={classes}
									nested
									to="/Export"
									icon={<MdHistory size={24} />}
									text={ls.exports}
								/>
							</List>
						</Collapse>,
						<AuthItem
							key={98}
							user={user}
							needRoles={["Business Admin", "Admin Wiipo Flex"]}
							classes={classes}
							to={`/Company/${user.business.businessId}`}
							icon={<MdBusiness size={24} />}
							text="Minha empresa"
						/>,
						<AuthItem
							key={99}
							user={user}
							needRoles={["Admin"]}
							classes={classes}
							to="/Charge"
							icon={<MdPaid size={24} />}
							text={ls.charges}
						/>
					]
						.filter(c => c)
						.sort((a, b) => (+a.key > +b.key ? 1 : -1))
						.map(c => (
							<div key={c.key}>{c}</div>
						))}
				</List>
			</Drawer>
			<ChangePasswordDrawer
				open={openChangePasswordDrawer}
				onClose={togglePasswordDrawer}
			/>
			<main className={classes.content}>
				<div className={classes.toolbar} />
				<Box
					width={
						openMenu
							? window.innerWidth - 240 - 32
							: window.innerWidth - 75 - 32
					}
				>
					{children}
				</Box>
			</main>
			{renderMenu()}
			<ModalNotification
				open={openNotification}
				handleClose={handleCloseNotification}
			/>
		</div>
	);
}

const mapStateToProps = ({ login, header: state }) => ({ login, state });

const mapDispatchToProps = {
	...actions,
	logout: loginActions.logoutUser
};

export default connect(mapStateToProps, mapDispatchToProps)(RenderHeader);
